import { BlogPost } from "../../common/models/blog_post";

export const post_0: BlogPost = {
    id: '6B83DB6B-AF5C-4994-B289-E47F505ED8DA',
    title: 'Creating Meaning in Solitude',
    content: `I've always dreamed of a life out of obligations.

    I've never signed up for trading my lifespan for some coins or made-up statuses in society. But, I ended up doing that, working for hours and getting paid in return. Stuck in the loop like most people ended up.
    
    My main fight with life is boredom, mediocracy, finding a way to claim my financial independence, and the final boss: creating a meaningful life after gaining the craved financial independence.
    
    As an individual in society, we are not used to choosing what we want to do. Instead, we somehow always struggle with something that we have to overcome which is highly possible to be a common thing we share in society. Economy, education, social status, taking care of a family, beliefs, or just being in a loop of some of the things I just listed.
    
    It's easy to follow a path in this context. If you need money to survive, means you were not born rich, you study for ~10–15 years hoping to find a job. The half of your life is already spent! Easy. If you end up somehow having a family, then you work and take care of your duties in the family. Those duties might be taking care of the house, your kid, or thinking about spending quality time with your partner which includes planning occasional vacations or shopping to make your living environment more appealing and enjoyable. All these things also take lots of time and energy which you don't even think about or calculate mostly.
    
    There is a common theme in these examples: you kind of know what to do to accomplish the task to fulfill the duty. But, what if you somehow end up retiring early, like 20s or 30s? Let's say you also don't have a family. Let's increase the bet and say you're single. You have money, you have time, and don't have any predefined duties or paths. Now, this setup makes your life way more difficult than the life you already have tons of duties to fulfill like in the other paths I initially listed.
    
    You have to find your own passions, you have to get to know yourself, get familiar with your inner desires, and discover ways of making your life meaningful without any predefined duties. You have to understand yourself and then you can create a meaningful life around you. It sounds like a lifelong therapy session. Well, this life was the life I signed up for :)
    
    Wish you a meaningful life!
    `,
    timeCreated: new Date('2023-10-15'),
    tags: ['solitude', 'meaning', 'independence'],
};