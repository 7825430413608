import React from "react";
import './custom_post_tag_style.css';

export interface CustomPostTagProps {
    tag: string;
}

export class CustomPostTag extends React.Component<CustomPostTagProps, any>{
    render(): React.ReactNode {
        return <p className='tag' style={
            {
                overflow: this.props.tag.length > 10 ? 'hidden' : undefined,
                marginBottom: 0,
            }
        }>{this.props.tag}</p>
    }
}