import { BlogPost } from "../common/models/blog_post";
import { User } from "../common/models/user";
import { PostManager } from "./post_manager";

export class DataManager {
    private user: User;
    private postManager: PostManager;

    constructor(user: User) {
        this.user = user;
        this.postManager = new PostManager(this);
    }

    public get defaultUser() {
        return this.user;
    }

    public getPosts(): BlogPost[] {
        const posts = this.postManager.posts;
        return (
            posts.sort(
                (a: BlogPost, b: BlogPost) => {
                    return a.timeCreated.getTime() < b.timeCreated.getTime() ? 1 : -1;
                }
            )
        );
    }

    public getPostById(id: string): BlogPost | undefined {
        return this.postManager.posts.find((post) => {
            return post.id === id;
        });
    }
}


export const dataManager = new DataManager({ fullName: 'Azmi Rutkay Biyik', shortName: 'arutkayb' });