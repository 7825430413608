
import './App.scss';
import { BlogSinglePageComponent } from './pages/blog_single/blog_single_page';
import { MainPage } from './pages/main/main_page';
import {
  createBrowserRouter,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom"
import { post_0 } from './data/posts/post_0';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <MainPage />,
    },

    {
      path: '/posts',
      element: <div> <Navigate to='/'></Navigate></div>,
    },
    {
      path: '/posts/:id',
      element: <BlogSinglePageComponent />
    }
  ]);

  return (
    <div className='background'>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
