import { BlogPost } from '../../common/models/blog_post';

export const post_1: BlogPost = {
    id: '9A9E5D00-F54E-42BC-82C6-EF4642C06FD4',
    title: 'Is That All There Is',
    content: `I was on the bus, to the office that I work eight hours a day. 

I saw a woman next to me, standing. She looked at me, I looked at her. She was almost my age, maybe a couple of years older. 

A moment later she made a quick move, she bent over her knees, I just heard some noise. 

She threw up a big chunk of barf on my pants. The bus continued at its regular pace and stopped when it arrived at the next stop. She glanced on my face briefly, with an absolute poker face. Then, took off the bus.

I carried on to my office. Life went on. I was stiff. I didn't feel anything. No anger, no rush to clean, didn't use any swear words. I completely accepted the event. Something happens, you act upon that thing. You see your shoes untied, you tie them. You don't feel anger or happiness or whatever the fuck feeling people are capable of feeling usually.

I do sense things, like wind. I definitely sense a breeze or sunburn. These are the mechanical sensory information that arrives my brain, what I call as senses. How I react to this information is, I guess, feelings.

When I touch a nice skin I sense the matte texture, some bumps, uneven parts to prove that we are organically grown meatballs. On the other hand, what I feel depends on the way I woke up that day, the length of the call I had with my sister, the amount of money left from my salary that month, number of times I touched that skin. It was my own skin anyway, I feel relieved. 

I exist from my perspective.
`,
    timeCreated: new Date('2023-10-29'),
    publisher: undefined,
    imageUrl: undefined,
    tags: ['subjectivity', 'Mr. Meeseeks'],
};

