import React from "react";
import { BlogPost } from "../../common/models/blog_post";
import './style/blog_style.css';
import { BlogHeader } from "./component/blog_header";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { dataManager } from "../../data/data_manager";

export interface BlogSinglePageProps {
    post: BlogPost,
}

class BlogSinglePage extends React.Component<BlogSinglePageProps, any> {
    componentDidMount(): void {
        window.scrollTo(0, 0);
    }

    render(): React.ReactNode {
        const locationPost: BlogPost = this.props.post!;

        return <div className='blog-single-post-body'>
            <BlogHeader image={locationPost.imageUrl} />
            <div className='single-blog-post-panel'>

                <div style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                    marginTop: 0,
                }}>

                    <h2 style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        padding: 0,
                        margin: 0,
                    }}>
                        {locationPost.title}
                    </h2>

                    <img
                        src={locationPost.imageUrl || ''}
                        style={
                            {
                                width: '100%',
                                objectFit: 'cover',
                                marginTop: 20,
                                marginBottom: 20,
                            }
                        }
                        className='blog-post-image' />

                    <p className='text-pre-line'>
                        {locationPost.content}
                    </p>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            fontSize: '14px',
                            marginTop: 50,
                        }}>
                        <span>{locationPost.publisher?.shortName}</span>
                        <div style={{ width: '10px' }}></div>
                        |
                        <div style={{ width: '10px' }}></div>
                        <span>{locationPost.timeCreated.toDateString()}</span>

                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}>
                        {
                            locationPost.tags?.slice(0, Math.min(locationPost.tags.length, 3,))
                                .map((tag) => {
                                    return <p key={locationPost.tags?.indexOf(tag)} className='tag' style={
                                        {
                                            overflow: tag.length > 10 ? 'hidden' : undefined,
                                            marginBottom: 0,
                                        }
                                    }>{tag}</p>
                                })
                        }
                    </div>
                </div>
                <Link style={{ textDecoration: 'none' }} to='/'>
                    <p style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        width: '100%',
                        marginTop: 20,
                        fontSize: 20
                    }}>
                        ←
                    </p>
                </Link>

            </div >
        </div>;
    }
}

export const BlogSinglePageComponent = (props: any) => {
    const { id } = useParams();

    if (id) {
        const post = dataManager.getPostById(id);

        if (post) {
            return <BlogSinglePage post={post} />
        } else {
            return <div> {id}
                <br />
                Post Not Found
                <Link style={{ textDecoration: 'none' }} to='/'>
                    <p style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        width: '100%',
                        marginTop: 20,
                        fontSize: 20
                    }}>
                        ←
                    </p>
                </Link></div>;
        }
    }
    else {
        return <div>
            Not Found
            <Link style={{ textDecoration: 'none' }} to='/'>
                <p style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    width: '100%',
                    marginTop: 20,
                    fontSize: 20
                }}>
                    ←
                </p>
            </Link></div>;
    }
}