import React from "react";
import '../style/main.scss';

export class MainHeader extends React.Component {
    render() {
        const smallScreen = window.matchMedia('(max-width: 780px)');

        return <div className="faded faded-left faded-right faded-bottom">
            <img
                src={'static/images/lonely_robot.webp'}
                alt="lonely robot cyberpunk"
                style={
                    {
                        width: '100vw',
                        maxHeight: '40vh',
                        minHeight: smallScreen ? '250px' : undefined,
                        objectFit: 'cover',
                        opacity: '0.6',
                    }
                } />
        </div>;
    }
}