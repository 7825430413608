import React from "react";

export type MainToolbarProps = {

};

export type MainToolbarState = {
};

export class MainToolbar extends React.Component<MainToolbarProps, MainToolbarState> {
    constructor(props: MainToolbarProps) {
        super(props);
    }

    render() {
        return <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '20px',
            marginBottom: '20px',
            marginTop: '5px',
        }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Blog</span>
                <span style={{ fontSize: '10px', textAlign: 'center' }}>(now)</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: 'gray' }}>Podcast</span>
                <span style={{ fontSize: '10px', textAlign: 'center' }}>(soon)</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: 'gray' }}>About</span>
                <span style={{ fontSize: '10px', textAlign: 'center' }}>(later)</span>
            </div>
        </div>;
    }
}