import { BlogPost } from "../common/models/blog_post";
import { DataManager } from "./data_manager";
import { postList } from "./post_list";

export class PostManager {

    constructor(dataManager: DataManager) {
        postList.forEach((post)=>{
            post.publisher = dataManager.defaultUser;
        });
    }

    public get posts() { return postList }
}

