import { BlogPost } from '../../common/models/blog_post';

export const post_2: BlogPost = {
    id: '286BEDD4-67EF-4233-81A8-F20D1FD0B551',
    title: 'Overloaded With Luggage and Overloaded With Common Sense',
    content: `Physical discomfort is important only when the mood is wrong. Then you fasten on to whatever thing uncomfortable and call that the cause.

You always suppress momentary anger at something you deeply and permanently hate.

Compassion comes from respect and from love; feeling pity or sorry comes from a  lack of respect and from fear.

You have a limit to the amount of abuse you'll accept, but no one in the whole world abuses you more than you abuse yourself. The limit of your self-abuse is the limit you will tolerate from other people.

We unknowingly alter our memories to fit with our current world views.

The desire for security and the feeling of insecurity are the same thing.

I check my heartbeat once in a while by asking if I wanna just die or live forever.
`,
    timeCreated: new Date('2024-02-20'),
    publisher: undefined,
    imageUrl: undefined,
    tags: ['heartbeat'],
};

