import React, { ReactElement, useState } from "react";
import { MainToolbar } from "./component/main_toolbar";
import { MainHeader } from "./component/main_header";
import { MainFooter } from "./component/main_footer";
import { BlogPage } from "../blog_main/blog_main_page";


export type MainPageState = {
    activeBody: number;
}

export class MainPage extends React.Component<any, MainPageState> {

    private bodyList: ReactElement[] = [
        <BlogPage />,
    ];

    constructor(props: any) {
        super(props);
        this.state = {
            activeBody: 0,
        };
    }

    render() {
        console.debug(this.state.activeBody);

        return <div className='main-page'>
            <MainHeader />
            <MainToolbar />
            {this.bodyList[this.state.activeBody]}
            <MainFooter />
        </div>;
    }
}