import React from "react";
import './style/blog_style.css';
import { BlogPost } from "../../common/models/blog_post";
import { BlogPostBody } from "./component/blog_post_body";
import { dataManager } from "../../data/data_manager";

export type BlogPageProps = {
};

export type BlogPageState = {
    posts: BlogPost[],
};

export class BlogPage extends React.Component<BlogPageProps, BlogPageState> {
    constructor(props: BlogPageProps) {
        super(props);
        const posts: BlogPost[] = dataManager.getPosts();

        this.state = {
            posts: posts
        };
    }

    render() {
        return <div className='blog-post-page'>
            <BlogPostBody
                posts={this.state?.posts ?? []}></BlogPostBody>
        </div>;
    }
}