import React, { Key } from "react";
import { BlogPost } from "../../../common/models/blog_post";
import '../style/blog_style.css';
import { CustomPostTag } from "../../../common/components/tag.tsx/custom_post_tag";
import { Link } from "react-router-dom";

export type BlogPostPanelProps = {
    post: BlogPost
};

export type BlogPostPanelState = {};

export class BlogPostPanel extends React.Component<BlogPostPanelProps, BlogPostPanelState>{
    render() {
        const postUrl: string = `/posts/${this.props.post.id}`;

        return <Link style={{ textDecoration: 'none' }} to={postUrl}>
            <div className='blog-post-panel'>
                <div style={{
                    marginTop: '5px',
                    marginLeft: '10px',
                    marginRight: '10px'
                }}>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            fontSize: '14px',
                        }}>
                        <span>{this.props.post.publisher?.shortName}</span>
                        <div style={{ width: '10px' }}></div>
                        |
                        <div style={{ width: '10px' }}></div>
                        <span>{this.props.post.timeCreated.toDateString()}</span>

                    </div>

                    <div className='blog-post-content-container'>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}>
                            <h2 style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',

                                marginTop: 0,
                                marginBottom: 0,
                            }}>
                                {this.props.post.title}
                            </h2>

                            <p className='text-wrap-ellipsis' >
                                {this.props.post.content}
                            </p>

                        </div>

                        {
                            this.props.post.imageUrl ? <img
                                src={this.props.post.imageUrl}
                                style={
                                    {
                                        width: '100%',
                                        maxHeight: '200px',
                                        objectFit: 'cover',
                                        opacity: '1',
                                        marginTop: 20,
                                        marginBottom: 20,
                                    }
                                } /> : <div />
                        }

                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        alignItems: 'center',
                    }}>
                        {
                            this.props.post.tags?.slice(0, Math.min(this.props.post.tags.length, 3,))
                                .map((tag) => {
                                    return <CustomPostTag key={this.props.post.tags?.indexOf(tag)} tag={tag} />
                                })
                        }
                    </div>

                    <p style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        marginBottom: 10,
                        marginTop: 0,
                        padding: 0,
                        fontSize: 20
                    }}>
                        →
                    </p>
                </div>

            </div >
        </Link>;
    }
}