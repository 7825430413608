import React from "react";
import { BlogPost } from "../../../common/models/blog_post";
import '../style/blog_style.css';
import { BlogPostPanel } from "./blog_post_panel";

export type BlogPostBodyProps = {
    posts?: BlogPost[]
};

export type BlogPostBodyState = {};

export class BlogPostBody extends React.Component<BlogPostBodyProps, BlogPostBodyState> {
    constructor(props: BlogPostBodyProps) {
        super(props);

        this.state = {};

        // sort by new to old
        this.props.posts?.sort((a, b) => {
            return b.timeCreated.getTime() - a.timeCreated.getTime();
        });
    }

    render() {
        return <div className='blog-post-body'>
            {
                this.props.posts?.map(post => {
                    const isLast = this.props.posts?.lastIndexOf(post) === ((this.props.posts?.length ?? 1) - 1);

                    return <div style={{ display: 'column' }} key={this.props.posts?.indexOf(post)} >
                        <BlogPostPanel post={post} />

                        <div style={{
                            borderTop: !isLast ? '1px dashed white' : undefined,
                            marginBottom: '10px',
                            marginTop: '10px'
                        }} />
                    </div>
                })
            }
        </div>;
    }
}