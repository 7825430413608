import React from "react";
import { Link } from "react-router-dom";

export class BlogHeader extends React.Component<{ image?: string }, any> {
    render() {
        const smallScreen = window.matchMedia('(max-width: 780px)');

        return <Link to='/'>
            <div className="faded faded-wide faded-left faded-right faded-bottom faded-top">
                <img
                    src={'/static/images/lonely_robot.webp'}
                    style={
                        {
                            width: '100vw',
                            maxHeight: '25vh',
                            minHeight: smallScreen ? '150px' : undefined,
                            objectFit: 'cover',
                            opacity: '0.6',
                            objectPosition: '50% 30%',
                        }
                    } />
            </div>
        </Link>;
    }
}